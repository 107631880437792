import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [5, 6, 7],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
The Reason
    </Text>
    <hr></hr>
    <p>{`I have a passion for writing.`}</p>
    <p>{`It's part of my hobbies. In this blog, I try to share my thoughts about anything that I care. I'm fascinated by entrepreneurship.`}</p>
    <p>{`The reason behind why some determined people make an enormous effort to solve unsolvable problems is always marvelous for me. There may be some scientists, mathematicians, or
physicians who solved or are solving complex problems. I respect them. I mean it. But I think the problems relating to human nature are more complex. In business, as in war,
you may face a situation with undetermined equations with quite a few variables. You cannot even determine the number of variables. And in this regard, I respect a lot for entrepreneurship.`}</p>
    <p>{`As an entrepreneur, I `}{`[experienced, is experiencing]`}{` interesting occasions with a lot of lessons and complexities. And maybe, in future, these posts would help, at least for myself, as I could look backward and connect the dots of my career.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      